import React from "react";
import CardService from "../ui/CardService";
import { useTranslation } from "react-i18next";
import IconLaw from "../../assets/icons/ic_law.svg";
import IconAs from "../../assets/icons/ic_assisstant.svg";
import IconSt from "../../assets/icons/ic_study.svg";
import IconSp from "../../assets/icons/ic_sp.svg";
import IconHoverAs from "../../assets/icons/ic_assisstant-white.svg";
import IconHoverSt from "../../assets/icons/ic_study-white.svg";
import IconHoverSp from "../../assets/icons/ic_sp-white.svg";

const Service = () => {
  const { t } = useTranslation("global");
  return (
    <div className="py-20 pb-28">
      <div className="responsive">
        {/* <h6 className='title-font text-center text-xs md:text-sm uppercase text-gray-700 font-semibold mb-2'>{t('header.slide-1-text-0')}</h6> */}
        <h1 className="title-font title-section text-center uppercase mb-12">
          {t("services.title")}
        </h1>
        {/* <p className='text-center text-xs md:text-base md:w-2/3 mx-auto mt-2 md:mt-6 mb-10'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim ipsa nam quam vero iste sed quas illum ipsum, corporis, magni sit aut nemo eius totam! Praesentium, dolores exercitationem. Nemo, eveniet.</p> */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <CardService
            icon={IconSp}
            iconHover={IconHoverSp}
            title={t("services.sub-title-1")}
            content={[
              "services.content-1-1",
              "services.content-1-2",
              "services.content-1-3",
              "services.content-1-4",
            ]}
            nbrWords={78}
          />
          <CardService
            icon={IconSt}
            iconHover={IconHoverSt}
            title={t("services.sub-title-3")}
            content={[
              "services.content-3-0",
              "services.content-3-1",
              "services.content-3-2",
              "services.content-3-3",
              "services.content-3-4",
            ]}
            nbrWords={300}
          />
          <CardService
            icon={IconAs}
            iconHover={IconHoverAs}
            title={t("services.sub-title-2")}
            content={[
              "services.content-2-1",
              "services.content-2-2",
              "services.content-2-3",
              "services.content-2-4",
            ]}
            nbrWords={68}
          />
        </div>
      </div>
    </div>
  );
};

export default Service;
