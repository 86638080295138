import { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import Logo from '../assets/logo/logo.png'
import IconMenu from '../assets/icons/ic_menu.svg'

const NavBar = ({ onOpen }) => {
    const { t, i18n } = useTranslation('global');

    useEffect(() => {
        AOS.init({duration:2000});
    }, [])

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        setIsScrolled(scrollTop > 400);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const navbarClass = isScrolled ? 'fixed-navbar' : 'absolute top-0 left-0 right-0 z-50';
    const navbarPaddingClass = isScrolled ? 'py-2' : 'py-6';

    return (
        // <nav className={`fixed w-full bg-gray mt-6 z-50`}>
        // <nav className={`${navbarClass}`}>
        <nav className={`${navbarClass}`}>
            <div className={` responsive w-full flex justify-between items-center ${navbarPaddingClass}`}>
                <div className='relative'>
                    <img src={Logo} alt="" className="w-32 md:w-48" data-aos='fade-out' data-aos-once="true" />
                    {/* <p className='absolute -right-56 bottom-1 md:bottom-2 text-[6px] md:text-[7px] tracking-[0.9px] md:tracking-[2.5px] text-white title-font !font-bold'>CONSEIL JURIDIQUE</p> */}
                </div>
                <ul className="hidden md:flex text-sm">
                    {/* <li  className="px-4 py-2 cursor-pointer">
                        <a href="#home" className='capitalize text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500'>
                            {t('navbar.home')}
                        </a>
                    </li> */}
                    <li  className="px-4 py-2 cursor-pointer">
                        <a href="#presentation" className='uppercase text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500'>
                            {t('navbar.apropos')}
                        </a>
                    </li>
                    <li  className="px-4 py-2 cursor-pointer">
                        <a href="#services" className='uppercase text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500'>
                            {t('navbar.services')}
                        </a>
                    </li>
                    <li  className="px-4 py-2 cursor-pointer">
                        <a href="#specialites" className='uppercase text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500'>
                            {t('navbar.specialites')}
                        </a>
                    </li>
                    <li  className="px-4 py-2 cursor-pointer">
                        <a href="#contact" className='uppercase text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500'>
                            {t('navbar.contact')}
                        </a>
                    </li>
                </ul>
                <ul className='md:hidden'>
                    <img src={IconMenu} alt="" onClick={onOpen} className='w-12'/>
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;