import React from "react";
import { useTranslation } from "react-i18next";
import Img2 from "../../assets/img/img11.jpg";

const Apropos = () => {
  const { t, i18n } = useTranslation("global");

  return (
    <div className="flex flex-col md:flex-row justify-between items-center md:space-x-8 responsive md:pt-10">
      <div className="md:basis-3/5">
        <h1 className="title-section mb-10 md:mr-20 title-font">
          {t("apropos.title")}
        </h1>
        <ul className=" first-letter:capitalize md:mr-20">
          <li className="mb-4 text-sm text-justify">
            {t("apropos.description-1")}
          </li>
          <li className="mb-4 text-sm text-justify">
            {t("apropos.description-2")}
          </li>
          <li className="mb-4 text-sm text-justify">
            {t("apropos.description-3")}
          </li>
          <li className="mb-4 text-sm text-justify">
            {t("apropos.description-4")}
          </li>
        </ul>
      </div>
      <div className="border-2 border-primary-100  rounded-lg overflow-hidden md:basis-2/5">
        {/* <img src={Img1} alt="" className='rounded-lg'/> */}
        <img src={Img2} alt="" className="w-full brightness-[.5]" />
      </div>
    </div>
  );
};

export default Apropos;
