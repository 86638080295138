import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { useTranslation } from "react-i18next";
import ImgWoman from "../../assets/img/img10.jpg";

const Header = () => {
  const { t } = useTranslation("global");

  return (
    <div
      className="h-screen 2xl:h-[700px] w-full relative bg-black overflow-hidden"
      id="home"
    >
      <div className="w-full h-full">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          // autoplay={{
          //     delay: 2500,
          //     disableOnInteraction: false,
          // }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper h-full "
        >
          {/* <SwiperSlide className='!flex flex-col justify-center space-y-2 mt-6 md:mt-10'>
                        <div className='responsive'>
                            <h6 className='title-font text-primary-100 text-sm md:text-lg text-center mb-2 font-semibold uppercase'>{t('header.slide-1-text-0')}</h6>
                            <h1 className='font-bold text-1xl md:text-4xl 2xl:text-5xl text-white text-center mb-8 capitalize w-full md:w-3/4 mx-auto'>{t('header.slide-1-text-1')}</h1>
                            <p className='text-white text-center md:w-4/5 2xl:w-2/3 mx-auto text-sm md:text-base'>
                                <p className='first-letter:text-primary-100 first-letter:font-semibold inline-block'>
                                    <p className='inline'>{t('header.slide-1-text-3')}, </p>
                                    <p className='inline'>{t('header.slide-1-text-4')}, </p>
                                    <p className='inline'>{t('header.slide-1-text-5')}</p>
                                </p> 
                            </p>
                        </div>
                    </SwiperSlide> */}

          <SwiperSlide className="!flex flex-col justify-center items-start space-y-2 mt-6 md:mt-10">
            <div className="responsive">
              <h6 className="title-font font-bold text-primary-100 text-sm md:text-2xl 2xl:text-3xl mb-4">
                {t("header.slide-1-text-0")}
              </h6>
              <h1 className="font-bold text-lg md:text-3xl 2xl:text-5xl text-white text-center mb-4 first-letter:text-primary-100 w-full mx-auto">
                {t("header.slide-1-text-1")}
              </h1>

              <p className="text-white w-full text-sm md:text-base mt-10">
                <p className="text-white w-full text-sm md:text-base">
                  <p className="first-letter:text-primary-100 first-letter:font-semibold">
                    <p className="first-letter:text-lg md:first-letter:text-2xl text-xs md:text-base first-letter:text-primary-100 first-letter:font-semibold">
                      {t("header.slide-1-text-3")} ?
                    </p>
                    <p className="first-letter:text-lg md:first-letter:text-2xl text-xs md:text-base first-letter:text-primary-100 first-letter:font-semibold">
                      {t("header.slide-1-text-4")} ?
                    </p>
                    <p className="first-letter:text-lg md:first-letter:text-2xl text-xs md:text-base first-letter:text-primary-100 first-letter:font-semibold">
                      {t("header.slide-1-text-5")} ?
                    </p>
                  </p>
                </p>
                <p className="mt-6 text-xs md:text-base first-letter:text-lg md:first-letter:text-2xl first-letter:text-primary-100 w-full first-letter:font-semibold inline-block">
                  <p className="inline">{t("header.slide-1-text-6")}</p>
                </p>
              </p>
            </div>
          </SwiperSlide>

          {/* <SwiperSlide className='!flex flex-col justify-center space-y-2 mt-6 md:mt-10'>
                        <div className='responsive'>
                            <h6 className='title-font text-primary-100 text-sm text-center mb-2 font-semibold uppercase'>Lorem, ipsum dolor.</h6>
                            <h1 className='font-bold text-4xl md:text-6xl text-white text-center mb-8 capitalize'>Lorem ipsum dolor sit.</h1>
                            <p className='text-white text-center md:w-1/2 2xl:w-1/3 mx-auto'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit, fugit. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quo, similique.</p>
                            <div className='mx-auto w-fit mt-28 md:mt-14'>
                                <a href='#contact' className='capitalize bg-primary-100 text-white w-fit px-8 py-4 rounded-lg'>{ t('header.slide-1-text-4')}</a>
                            </div>
                        </div>
                    </SwiperSlide> */}
        </Swiper>
      </div>
      <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full h-full flex justify-end">
        <img
          src={ImgWoman}
          alt=""
          className="w-full h-full object-cover brightness-[.3]"
        />
      </div>
    </div>
  );
};

export default Header;
