import NavBar from "./layout/NavBar";
import { useTranslation } from "react-i18next";
import Service from "./components/sections/Service";
import Apropos from "./components/sections/Apropos";
import Contact from "./components/sections/Contact";
import Header from "./components/sections/Header";
import Specialite from "./components/sections/Specialite";
import Talk from "./components/sections/Talk";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Helmet } from "react-helmet";

function App() {
  const { t, i18n } = useTranslation("global");
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <div className="w-full overflow-hidden">
      {/* <div className='fixed mt-[500px] bg-black w-full text-center z-50 text-white dark:bg-white dark:text-secondary-200'>
        VERSION BETA v1.0.0 - UNDER DEVELOPMENT
      </div> */}

      <Helmet>
        <title>Your Page Title</title>
        <meta name="description" content="mira conseil juridique" />
        <meta
          name="keywords"
          content="juridique, conseil, mira, mira conseil, mira conseil juridique, mira avocat, mira alger"
        />
        {/* Add more meta tags as needed */}
      </Helmet>

      <NavBar onOpen={onOpen} />

      <Header />

      <div id="presentation"></div>
      <div className="mt-20"></div>
      <Apropos />

      <div id="services"></div>
      <div className="hidden md:block w-1/3 border-b-2 mx-auto mt-28"></div>

      <Service />

      <Talk />

      <Specialite />

      <Contact />

      <footer></footer>

      <Drawer isOpen={isOpen} onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent className="!bg-black">
          <DrawerCloseButton className="text-white mt-2" />
          <DrawerHeader className="text-white">
            Create your account
          </DrawerHeader>

          <DrawerBody>
            <ul className="text-sm space-y-4 mt-10">
              <li className="px-4 py-2 cursor-pointer border-b pb-4">
                <a
                  onClick={() => onClose()}
                  href="#presentation"
                  className="capitalize text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500"
                >
                  {t("navbar.apropos")}
                </a>
              </li>
              <li className="px-4 py-2 cursor-pointer border-b pb-4">
                <a
                  onClick={() => onClose()}
                  href="#services"
                  className="capitalize text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500"
                >
                  {t("navbar.services")}
                </a>
              </li>
              <li className="px-4 py-2 cursor-pointer border-b pb-4">
                <a
                  onClick={() => onClose()}
                  href="#specialites"
                  className="capitalize text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500"
                >
                  {t("navbar.specialites")}
                </a>
              </li>
              <li className="px-4 py-2 cursor-pointer border-b">
                <a
                  onClick={() => onClose()}
                  href="#contact"
                  className="capitalize text-lg font-medium text-white hover:text-primary-100 hover:border-b-2 hover:border-b-primary-100 transform duration-500"
                >
                  {t("navbar.contact")}
                </a>
              </li>
            </ul>
          </DrawerBody>

          <DrawerFooter></DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default App;
