import React from "react";
import { useTranslation } from "react-i18next";
import SocialMedia from "../ui/SocialMedia";
import ContactForm from "../form/ContactForm";
import Img from "../../assets/img/img13.jpg";

const Contact = () => {
  const { t } = useTranslation("global");

  return (
    <div className="relative h-[1450px] md:h-[750px] w-full">
      <img
        src={Img}
        alt=""
        className="w-full h-full brightness-[.4]  object-cover"
      />
      <div id="contact"></div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6 responsive justify-between absolute left-0 top-0 right-0 py-20 w-full">
        <div className="">
          <h1 className="title-font title-section">{t("contact.title")}</h1>
          <SocialMedia />
          <h3 className="title-font text-xl font-medium mb-4 text-white mt-8">
            {t("contact.sub-title")}
          </h3>
          <p className="text-xs md:text-sm mt-2 !text-white">
            {t("contact.description-1")}
          </p>
          <p className="text-xs md:text-sm mt-2 !text-white">
            {t("contact.description-2")}
          </p>
          <p className="font-bold text-xs md:text-sm mt-2 !text-white">
            {t("contact.description-3")}
          </p>

          <div className="mt-10 bg-bg-100 rounded-md flex flex-col justify-center items-center bg-black bg-opacity-60 basis-1/3 text-white overflow-hidden">
            {/* <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d12790.128906749405!2d3.0905055999755837!3d36.73379407814716!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128e53ce3076e333%3A0x8c8bc93c6c16891a!2sMer%20et%20soleil%20metro!5e0!3m2!1sen!2sdz!4v1696706737056!5m2!1sen!2sdz"
                            width="100%" height="244px" frameborder="0"
                            style={{ border: 0, borderRadius:'10px'}} allowfullscreen=""
                            ariaHidden="false" tabindex="0"></iframe> */}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d799.454484950417!2d3.035890573016351!3d36.726933476232304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128fada6b158e479%3A0x544e16bbd1920421!2smira%20conseil%20juridique!5e0!3m2!1sfr!2sdz!4v1697618200066!5m2!1sfr!2sdz"
              width="100%"
              height="244px"
              frameBorder="0"
              style={{ border: 0, borderRadius: "10px" }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
        <div className="w-full">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
