import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import Img1 from "../../assets/img/img21.jpg";
import Img2 from "../../assets/img/img12.jpg";
import Img3 from "../../assets/img/img20.jpg";

const CardService = ({ icon, iconHover, title, content, nbrWords }) => {
  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  var Img = Img1;
  if (title.includes("Etude")) Img = Img3;
  else if (title.includes("Assistance")) Img = Img2;

  return (
    <div className="bg-gray-100 shadow-sm rounded-lg px-4 py-8 relative h-[280px] md:h-[290px] hover-group group hover:bg-primary-100 hover:bg-opacity-100 transform duration-500">
      <img
        src={icon}
        alt=""
        className="group-hover:hidden w-14 md:w-20 mx-auto mb-4 md:mb-4"
      />
      <img
        src={iconHover}
        alt=""
        className="hidden group-hover:block w-14 md:w-20 mx-auto mb-4 md:mb-4"
      />
      <div className="text-md md:text-lg text-center h-8 title-font">
        {/* {title} */}
        <div
          className="inline font-bold"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        {/* {title === 'Etude & Formation' &&
                <p className={`-mb-4 text-primary-100 first-letter:font-semibold group-hover:text-white  text-center text-xs md:text-sm`}>{t('services.content-3-0')}</p>
            } */}
      </div>
      <ul className="space-y-4 mt-2 mb-6">
        {content.map((item, idx) => {
          return (
            <p
              key={idx}
              className={`h-10 first-letter:text-primary-100 group-hover:first-letter:text-white first-letter:font-semibold group-hover:text-white  text-center text-xs md:text-sm ${
                idx > 0 && "hidden"
              }`}
            >
              {t(item).substring(0, nbrWords)} ...
            </p>
          );
        })}
      </ul>
      <div className="w-full flex items-center justify-center">
        <button
          onClick={onOpen}
          className="capitalize bg-primary-100 group-hover:bg-white group-hover:text-primary-100 rounded-lg py-2 px-4 text-white text-sm font-semibold"
        >
          {t("global.voir-plus")}
        </button>
      </div>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="!text-sm md:!text-base">
            <div
              className="inline"
              dangerouslySetInnerHTML={{ __html: t(title) }}
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <img
              src={Img}
              alt=""
              className="h-[200px] w-full object-cover rounded-lg"
            />
            <div className="mt-4 mb-4 space-y-4">
              {/* {title === 'Etude & Formation' &&
                            <p className={`text-primary-100 first-letter:font-semibold text-xs md:text-sm`}>{t('services.content-3-0')}</p>
                        } */}
              {content.map((item, idx) => (
                <div
                  key={idx}
                  className={`
                    ${
                      title !== "" &&
                      "first-letter:text-primary-100 first-letter:font-semibold"
                    } 
                    text-xs md:text-sm text-justify`}
                >
                  {/* {t(item)} */}
                  {title.includes("Conseil") ? (
                    <div className="font-bold">
                      <div
                        className="inline"
                        dangerouslySetInnerHTML={{ __html: t(item) }}
                      />
                    </div>
                  ) : title.includes("Etude") ? (
                    idx > 1 && (
                      <div
                        className="inline"
                        dangerouslySetInnerHTML={{ __html: t(item) }}
                      />
                    )
                  ) : (
                    <div
                      className="inline"
                      dangerouslySetInnerHTML={{ __html: t(item) }}
                    />
                  )}
                </div>
              ))}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CardService;
