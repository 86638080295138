import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'

import global_fr from './lang/fr/global.json'
import i18next from 'i18next'
import { I18nextProvider } from 'react-i18next';
import { ChakraProvider } from '@chakra-ui/react';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "fr",
  resources: {
    fr: {
      global: global_fr
    }
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </ChakraProvider>
  </React.StrictMode>
);