import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import { useTranslation } from "react-i18next";
import IconSend from "../../assets/icons/ic_send.svg";
import {
  CircularProgress,
  CircularProgressLabel,
  Spinner,
  useToast,
} from "@chakra-ui/react";

const ContactForm = () => {
  const [msg, setMsg] = useState("");

  const [prenom, setPrenom] = useState("");
  const [nom, setNom] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [message, setMessage] = useState("");
  const [societe, setSociete] = useState("");
  const [objet, setObjet] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  const { t } = useTranslation("global");

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    emailjs
      .sendForm(
        "service_8cpprhb",
        "template_56x9bv9",
        e.target,
        "eqPsod5TNV_R3MYO7"
      )
      .then((result) => {
        setMsg("Message envoye");
        setPrenom("");
        setEmail("");
        setTel("");
        setMessage("");
        setNom("");
        setSociete("");
        setObjet("");
        toast({
          title: "Message envoyé",
          description: "Nous avons envoyé votre demande.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.text);
        setIsLoading(false);
        // Handle error, e.g., show error message
      });
  };
  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 gap-x-6 w-full bg-black md:bg-opacity-80 rounded-lg p-4 items-center"
      onSubmit={handleSubmit}
    >
      <input
        type="text"
        name="prenom"
        id="prenom"
        value={prenom}
        onChange={(e) => setPrenom(e.target.value)}
        placeholder={t("contact.prenom")}
        required
        className="capitalize bg-transparent w-full border placeholder-secondary-50 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />
      <input
        type="text"
        name="nom"
        id="nom"
        value={nom}
        onChange={(e) => setNom(e.target.value.toUpperCase())}
        placeholder={t("contact.nom")}
        required
        className="capitalize bg-transparent w-full border placeholder-secondary-50 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />
      {/* <input type="text" name="fonction" id="fonctoin" placeholder={t('contact.fonction')} className='bg-transparent w-full border placeholder-secondary-50 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100'/> */}

      <input
        type="email"
        name="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder={t("contact.email")}
        required
        className="capitalize bg-transparent w-full border placeholder-secondary-200 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />

      <input
        type="tel"
        name="tel"
        id="phone"
        value={tel}
        onChange={(e) => setTel(e.target.value)}
        placeholder={t("contact.phone")}
        required
        className="capitalize bg-transparent w-full border placeholder-secondary-200 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />

      <input
        type="text"
        name="societe"
        id="societe"
        value={societe}
        onChange={(e) => setSociete(e.target.value)}
        placeholder={t("contact.societe")}
        className="capitalize md:col-span-2 bg-transparent w-full border placeholder-secondary-50 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />
      <input
        type="text"
        name="objet"
        id="text"
        value={objet}
        onChange={(e) => setObjet(e.target.value)}
        placeholder={t("contact.objet")}
        required
        className="capitalize md:col-span-2 bg-transparent w-full border placeholder-secondary-200 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100"
      />

      <textarea
        name="message"
        id="message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder={t("contact.message")}
        required
        cols="30"
        rows="10"
        className="capitalize md:col-span-2 bg-transparent w-full border placeholder-secondary-200 text-white mt-4 rounded-md outline-none px-3 py-4 text-xs md:text-sm bg-bg-100 min-h[80px] md:min-h-[100px]"
      ></textarea>

      <div className="md:col-span-2">
        {isLoading ? (
          <div className="flex items-center rounded-md mt-4 bg-secondary-200 py-4 px-8 w-fit mx-auto bg-primary-100 col-span-2">
            <Spinner color="white" />
          </div>
        ) : (
          <button
            type="submit"
            className="flex items-center rounded-md mt-4 bg-secondary-200 py-4 px-8 w-fit mx-auto bg-primary-100 col-span-2"
          >
            <p className="mr-4 text-white font-semibold uppercase">
              {t("contact.envoyer")}
            </p>
            <img src={IconSend} alt="" className="w-5" />
          </button>
        )}
      </div>
    </form>
  );
};

export default ContactForm;
