import React from 'react'

import IconMail from '../../assets/icons/ic_mail.svg'
import IconPhone from '../../assets/icons/ic_phone.svg'

const SocialMedia = () => {
    return (
        <>
            <ul className='w-full'>
                <li className='grid grid-cols-12 mt-4'>
                    <img src={IconMail} alt="" className='w-6'/>
                    {/* <h4 className='font-bold text-2xl mb-4'>Email</h4> */}
                    <a href='mailto:contact@mira-conseil.com' className='text-white text-sm self-center col-span-11'>
                        contact@mira-conseil.com
                    </a>
                </li>
                <li className='grid grid-cols-12 mt-4'>
                    <img src={IconPhone} alt="" className='w-6'/>
                    <a href='tel:+213770015114' className='text-white text-sm self-center col-span-11'>
                        +213 (0) 770 015 114
                    </a>
                </li>
                {/* 
                <li className='bg-bg-100 rounded-md flex flex-col justify-center items-center bg-black bg-opacity-80 basis-1/3 text-white p-8'>
                    <img src={IconPhone} alt="" className='w-20 mb-8'/>
                    <h4 className='font-bold text-2xl mb-4'>Phone</h4>
                    <a href='tel:+213552559966' className='text-sm self-center'>
                        +213552559966
                    </a>
                </li> */}
            </ul>
        </>
    )
}

export default SocialMedia
