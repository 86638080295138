import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const CardSpecialite = ({ icon, title, content }) => {
  const { t } = useTranslation("global");
  const [show, setShow] = useState(false);

  return (
    <div className="space-y-6">
      <div className="border border-primary-100 rounded-lg p-4 md:p-8 relative h-fit shadow-md">
        <img src="" alt="" />
        <h3 className="text-lg md:text-xl font-semibold  h-10 md:h-12 text-primary-100">
          {t("specialite.droit-1")}
        </h3>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-1-text-1")}
        </p>
        <p
          className={`text-xs md:text-sm mb-2 md:mb-4 first-letter:text-primary-100 first-letter:font-semibold`}
        >
          {t("specialite.droit-1-text-2")}
        </p>
        <ul className="space-y-2 md:space-y-4 list-inside list-disc">
          <li className="text-xs md:text-sm">
            {t("specialite.droit-1-text-3")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-1-text-4")}
          </li>
          <li className="text-xs md:text-sm">
            {/* {t('specialite.droit-1-text-5')} */}
            <div
              className="inline"
              dangerouslySetInnerHTML={{
                __html: t("specialite.droit-1-text-5"),
              }}
            />
          </li>
        </ul>
      </div>

      <div className="border border-primary-100 rounded-lg p-4 md:p-8 relative h-fit shadow-md">
        <img src="" alt="" />
        <h3 className="text-lg md:text-xl font-semibold  h-10 md:h-12 ">
          {t("specialite.droit-2")}
        </h3>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-2-text-1")}
        </p>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-2-text-2")}
        </p>
        <ul className="space-y-2 md:space-y-4 list-inside list-disc">
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-3")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-4")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-5")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-6")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-7")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-2-text-8")}
          </li>
        </ul>
        <p
          className={`text-xs md:text-sm first-letter:text-primary-100 first-letter:font-semibold mt-4`}
        >
          {t("specialite.droit-2-text-9")}
        </p>
      </div>

      <div className="border border-primary-100 rounded-lg p-4 md:p-8 relative h-fit shadow-md">
        <img src="" alt="" />
        <h3 className="text-lg md:text-xl font-semibold  h-fit mb-4 md:mb-0 md:h-12">
          {t("specialite.droit-3")}
        </h3>
        <p
          className={`text-xs md:text-sm mb-2 md:mb-4 first-letter:text-primary-100 first-letter:font-semibold`}
        >
          {t("specialite.droit-3-text-1")}
        </p>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-3-text-2")}
        </p>
        <p className={`text-xs md:text-sm`}>{t("specialite.droit-3-text-3")}</p>
      </div>

      <div className="border border-primary-100 rounded-lg p-4 md:p-8 relative h-fit shadow-md">
        <img src="" alt="" />
        <h3 className="text-lg md:text-xl font-semibold  h-10 md:h-12 first-letter:text-primary-100">
          {t("specialite.droit-4")}
        </h3>
        <div className={`text-xs md:text-sm mb-2 md:mb-4`}>
          <div
            className="inline"
            dangerouslySetInnerHTML={{ __html: t("specialite.droit-4-text-1") }}
          />
        </div>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-4-text-2")}
        </p>
        <ul className="space-y-2 md:space-y-4 list-inside list-disc">
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-3")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-4")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-5")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-6")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-7")}
          </li>
          <li className="text-xs md:text-sm">
            {t("specialite.droit-4-text-8")}
          </li>
        </ul>
      </div>

      <div className="border border-primary-100 rounded-lg p-4 md:p-8 relative h-fit shadow-md">
        <img src="" alt="" />
        <h3 className="text-lg md:text-xl font-semibold  h-10 md:h-12 text-primary-100">
          {t("specialite.droit-5")}
        </h3>
        <div className={`text-xs md:text-sm mb-2 md:mb-4`}>
          <div
            className="inline"
            dangerouslySetInnerHTML={{ __html: t("specialite.droit-5-text-1") }}
          />
        </div>
        <div className={`text-xs md:text-sm mb-2 md:mb-4`}>
          <div
            className="inline"
            dangerouslySetInnerHTML={{ __html: t("specialite.droit-5-text-2") }}
          />
        </div>
        <p
          className={`text-xs md:text-sm mb-2 md:mb-4 first-letter:font-semibold first-letter:text-primary-100`}
        >
          {t("specialite.droit-5-text-3")}
        </p>
        <p className={`text-xs md:text-sm mb-2 md:mb-4`}>
          {t("specialite.droit-5-text-4")}
        </p>
        <p
          className={`text-xs md:text-sm mb-2 md:mb-4 first-letter:font-semibold`}
        >
          {t("specialite.droit-5-text-5")}
        </p>
        <p className={`text-xs md:text-sm first-letter:font-semibold`}>
          {t("specialite.droit-5-text-6")}
        </p>
      </div>
    </div>
  );
};

export default CardSpecialite;
