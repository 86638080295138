import React from "react";
import { useTranslation } from "react-i18next";
import Img from "../../assets/img/img12.jpg";
import CardSpecialite from "../ui/CardSpecialite";

const Talk = () => {
  const { t } = useTranslation("global");
  return (
    <div className="mb-20 relative overflow-hidden bg-black h-fit">
      <img
        src={Img}
        alt=""
        className="absolute left-0 top-0 -z-1 h-full w-full object-cover object-bottom brightness-[.3]"
      />
      <div className="py-20 text-white flex flex-col items-center justify-center h-fit z-20 relative">
        <div className="w-fit mx-4 md:mx-auto text-right text-xl h-fit">
          <h1 className="text-primary-100 font-bold text-sm md:text-2xl 2xl:text-3xl mb-4 md:mb-8 capitalize w-full text-right">
            ميرا للاستشارات القانونية رفيقكم القانوني لضمان مصالحك...
            <span className="text-transparent">ت</span>
          </h1>
          <p className="mb-4 text-xs md:text-base">
            ميرا للاستشارات القانونية تتكفل بضمان ورعاية حقوقكم وفق الأحكام
            القانونية السارية المفعول.
            <span className="text-transparent">ت</span>
          </p>
          <p className="mb-4 text-xs md:text-base">
            ميرا للاستشارات القانونية تحيطكم بالعلم بكل القوانين التي تفيدكم
            وكذا تطورها.
            <span className="text-transparent">ت</span>
          </p>
          <p className="mb-4 text-xs md:text-base">
            ميرا للاستشارات القانونية تقترح أراء استشارية وحلول موضوعية كما
            تتكفل بالمفاوضات العادية أو الودية تجنبا للعراقيل الناجمة عن
            العلاقات مع الغير.
            <span className="text-transparent">ت</span>
          </p>
          <p className="mb-4 text-xs md:text-base">
            ميرا للاستشارات القانونية تتكفل بتحرير العرائض القانونية والمراسلات
            الإدارية وكذا كافة العقود الخاصة أو التأسيسية مع مراعاة الشروط
            القانونية لذلك.
            <span className="text-transparent">ت</span>
          </p>
          <p className="mb-4 text-xs md:text-base">
            ميرا للاستشارات القانونية تضمن المرافقة القانونية للشركات سواء كانت
            مدنية أم تجارية منذ نشأتها إلى غاية تطورها، مع مراعاة أهدافها.
            <span className="text-transparent">ت</span>
          </p>
          <p className="text-xs md:text-base">
            {" "}
            تتكفل بمتابعة سير وصحة الاجراءات القانونية على مستوى كل مرحلة قضائية
            من الدعوى كما تسعي بكل الطرق لاستهداف مصالحكم.
            <span className="text-transparent">ت</span>
          </p>
        </div>
        {/* <h2 className='title-font text-center w-2/3 mx-auto -mt-16 md:-mt-4 text-white font-semibold text-2xl md:text-6xl'>{t('header.slide-1-text-0')}</h2> */}
        {/* <p className='font-light text-center md:w-2/3 mx-auto text-xs md:text-base mt-6 mb-10 text-white'>{t('specialite.description')}</p> */}
        {/* <a href="#contact" className='bg-primary-100 rounded-lg px-4 py-2 capitalize text-white font-medium'>discuter</a> */}
      </div>
      <div id="specialites"></div>
    </div>
  );
};

export default Talk;
