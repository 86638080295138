import React from 'react'
import { useTranslation } from 'react-i18next'
import IconSp from '../../assets/icons/ic_sp.svg'
import CardSpecialite from '../ui/CardSpecialite'

const Specialite = () => {
    const { t } = useTranslation('global')
    return (
        <div>
            <div className='responsive pb-20'>
                {/* <h6 className='title-font text-center text-sm uppercase text-gray-700 font-semibold mb-2'>{t('header.slide-1-text-0')}</h6> */}
                <h1 className='title-font title-section text-center'>{t('specialite.title')}</h1>
                <p className=' text-xs md:text-base text-center md:w-2/3 mx-auto mt-4 md:mt-6 mb-10'>
                    {t('specialite.description-1')}
                </p>
                <div className='grid grid-cols-1 gap-4'>
                    <CardSpecialite icon={''} title={t('services.sub-title-1')} content={['services.content-1-1','services.content-1-2','services.content-1-3','services.content-1-4']}/>
                </div>
            </div>
            <div id='contact'></div>
        </div>
    )
}

export default Specialite